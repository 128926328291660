import React from 'react';
import {
Nav,
NavLink,
Bars,
NavLinkHide1,
NavLinkHide2,
NavMenu
} from './navbarelements';

const Navbar = () => {
return (
	<>
	<Nav>
		<Bars />

		<NavMenu>
		<NavLink to='/'>
			Home
		</NavLink>
		<NavLinkHide1 to='/elektriciteit'>
			Elektriciteit
		</NavLinkHide1>
		<NavLinkHide1 to='/loodgieter'>
			Loodgieterij
		</NavLinkHide1>
		<NavLinkHide2 to='/schilderwerken'>
			Schilderwerken
		</NavLinkHide2>
		<NavLinkHide2 to='/afbraakwerken'>
			Afbraakwerken
		</NavLinkHide2>
		<NavLinkHide2 to='/afwerking'>
			Afwerkingen
		</NavLinkHide2>
		<NavLink to='/diensten'>
			Alle diensten
		</NavLink>
		<NavLink to='/contact'>
			Contact
		</NavLink>
		</NavMenu>
	</Nav>
	</>
);
};

export default Navbar;
