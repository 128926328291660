import React, { useRef } from 'react';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn, SubTitle } from '../components/detailcomponents';
import emailjs from "@emailjs/browser";

import meneer from '../images/StandaardMeneer.png';

const Contact = () => {
	
	const form = useRef();

	const sendMail = (e) => {
		e.preventDefault();

		console.log("Mail send");
		//Dashboard: https://dashboard.emailjs.com/admin
		emailjs.sendForm('service_w6pi7ce', 'Fixbus_template', form.current , 'DiAevcbFWsIsO2Mvq');

		window.location.reload(false);
	}


return (
	<MainContainer>
    <head><meta name="keywords" content="Kempen, contact, lier, antwerpen, zoersel"/></head>
	<Title>Contact</Title>

	<SubContainer>
		<ProjectContainer>
			<DetailGrid>
				<DetailColumn>
					<Image src={meneer} height="250rem" />
				</DetailColumn>
				<DetailColumn>
                    <h2><b>De Fixbus</b></h2>
                    <h5>Heb je een klus? Bel de fixbus!</h5>
                    <br />
                    <Text>Telefoon: <a href='tel:0467022679'>0467/02.26.79</a></Text>
                    <Text>Email: <a href='mailto:info@defixbus.be'>info@defixbus.be</a></Text>
				</DetailColumn>
			</DetailGrid>
		</ProjectContainer>
		<ProjectContainer>
			<SubTitle>Contact formulier</SubTitle>
			<form ref={form} id='contactForm'>
				<label>Voornaam:</label>
				<input id='firstName' name='firstName' type='text' required/> <br />
				<label>Familienaam:</label>
				<input id='lastName' name='lastName'  type={'text'} required/> <br />
				<label>Gsm-nummer:</label>
				<input type={'tel'} id='phonenumber' name='phonenumber'  /> <br />
				<label>Email:</label>
				<input type={'email'} id='email' name='email'  required/> <br />
				<label>Onderwerp:</label>
				<select id="subject" name="subject">
					<option value="Afbraakwerken">Afbraakwerken</option>
					<option value="Kleineprojecten">Kleine projecten</option>
					<option value="Opruimen">Opruimen</option>
					<option value="Afwerking">Afwerking</option>
					<option value="Schilderwerken">Schilderwerken</option>
					<option value="Electriciteit">Electriciteit</option>
					<option value="Loodgieterij">Loodgieterij</option>
					<option value="Stellingswerken">Stellingswerken</option>
					<option value="Dakwerken">Dakwerken</option>
					<option value="Tuinwerken">Tuinwerken</option>
					<option value="ComputerProblemen">Computer problemen</option>
					<option value="Vervoer">Vervoer</option>
					<option value="Varia">Varia</option>
				</select><br />
				<label>Bericht:</label>
				<textarea id='message' name='message' required />
				<input type={'submit'} value='Versturen' onClick={sendMail} />
			</form>
		</ProjectContainer>
	</SubContainer>
	</MainContainer>
);
};

export default Contact;
