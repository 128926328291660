import React from 'react';
import { FaPhone, FaEnvelope } from "react-icons/fa"
import { AppHeader, HeaderTitle, PrimaryButton, SecondaryButton, Href, Grid, ColumnLeft, ColumnRight } from './headerelements';

const Header = () => {
    return (
        <AppHeader>
            <Grid>
                <ColumnLeft><HeaderTitle>De Fixbus</HeaderTitle></ColumnLeft>
                <ColumnRight>
                    <p><PrimaryButton><Href href='tel:0467022679'><FaPhone />&nbsp; 0467/02.26.79</Href></PrimaryButton>&nbsp;&nbsp;&nbsp;
                    <SecondaryButton><Href href='mailto:info@defixbus.be'><FaEnvelope />&nbsp; info@defixbus.be</Href></SecondaryButton></p>
                </ColumnRight>
            </Grid>
        </AppHeader>
    )
}

export default Header; 