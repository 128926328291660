import React from 'react';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn } from '../components/detailcomponents';

import meneer from '../images/StandaardMeneer.png'

const computer = () => {
return (
	<MainContainer>
	<head><meta name="keywords" content="Kempen, computer, tablet, smartphone, apple, email, lier, antwerpen, zoersel"/></head>
	<Title>Computer problemen</Title>

	<SubContainer>
		<ProjectContainer>
			<DetailGrid>
				<DetailColumn>
					<Image src={meneer} height="250rem" />
				</DetailColumn>
				<DetailColumn>
					<Text>
					Een nieuwe PC kopen? Pc vastgelopen? Een website maken? Ons team staat graag voor je klaar. 
					</Text>
					<ul>
						<li>Advies bij het kopen van een nieuwe computer</li>
						<li>Instellen van een nieuwe computer, smartphone, tablet, ...</li>
						<li>Printer installeren</li>
						<li>Programma's die niet meer openen of werken nakijken</li>
						<li>En nog veel meer!</li>
					</ul>
				</DetailColumn>
			</DetailGrid>
		</ProjectContainer>
	</SubContainer>
	</MainContainer>
);
};

export default computer;
