import React from 'react';
import { AppFooter } from './footerelements';

const Footer = () => {
    return (
        <AppFooter>
            <p>De Fixbus - BTW 0798.520.925</p>
            <p><a href='/privacy'>Privacybeleid</a> <br />
                Copyright © 2023 – De Fixbus. Alle rechten voorbehouden.
            </p>
        </AppFooter>
    )
}

export default Footer;