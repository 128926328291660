import React from 'react';
import { MainContainer, SubContainer, SubTitle, ProjectContainer, Text, Grid, Column, ImageGrid, DetailGrid, ListItem  } from '../components/detailcomponents';
import { HeaderSmall, HeaderBig, ColumnMid, GridMid, List } from '../components/headerelements';
import { NavLink } from 'react-router-dom';
import meneer from '../images/StandaardMeneer.png'

const Home = () => {
return (
	<MainContainer>
		<SubContainer>
    	<head><meta name="keywords" content="Kempen, diensten, klusjesdienst, klusjesman, werkman, lier, antwerpen, zoersel"/></head>
		<SubTitle>Waarom een klusjesman?</SubTitle>
			<ProjectContainer>
				<Text>
					<p>
						Een klusjesman komt naar jou toe om zaken in orde te krijgen. Ze verzorgen de klusjes in huis, maar ook voor de zaak. <br />
						Dit kan gaan van het ophangen van een schilderij, tot het repareren van een lekkende kraan. 
						Ook bij verbouwing staan klusjesmannen klaar om je een handje te helpen bij het plaatsen van gyproc-muren of bij het leggen van de elektriciteit.
					</p>
				</Text>
			</ProjectContainer>
		</SubContainer>

		<SubContainer>
			<SubTitle>Wie huurt een klusjeman?</SubTitle>
			<ProjectContainer>
				<Text>
					<p>Veel mensen maken gebruik van een klusjesman.</p>
					<Grid>
						<Column>
							<p>Particulieren</p>
							<List>
							<ListItem><p>U heeft er geen tijd voor</p></ListItem>
							<ListItem><p>U heeft niet de juiste kennis om het uit te voeren</p></ListItem>
							<ListItem><p>U bent op latere leeftijd</p></ListItem>
							<ListItem><p>Er zijn veel andere zaken aan uw hoofd</p></ListItem>
							</List>
						</Column>
						<Column>
							<ImageGrid src={meneer} height="250rem" />
						</Column>
						<Column>
							<p>Bedrijven</p>
							<List>
							<ListItem>Uw werknemers hebben hier geen kennis over</ListItem>
							<ListItem>Het is te druk om uw werknemers de klus te laten uitvoeren</ListItem>
							<ListItem>U heeft graag de zekerheid en experties van een werkman</ListItem>
							<ListItem>Er zijn veel andere zaken aan uw hoofd</ListItem>
							</List>
						</Column>
					</Grid>
				</Text>
			</ProjectContainer>
		</SubContainer>

		<SubContainer>
			<SubTitle>Onze diensten</SubTitle>
			<p>We werken aan verschillende projecten, zoals de onderstaande. <br />
			<b>Maar ook voor klusjes die niet staan opgelijst, kan u bij ons terecht!</b></p>
			<ProjectContainer>
				<DetailGrid>
					<Column>
						<HeaderSmall>Kleinere werken</HeaderSmall>
						<GridMid>
						<ColumnMid>
						<NavLink to='/opruimingen'>Opruimen</NavLink>
						<NavLink to='/afwerking'>Afwerkingen</NavLink>
						<NavLink to='/schilderwerken'>Schilderwerken</NavLink>
						<NavLink to='/electriteit'>Electriciteit</NavLink>
						</ColumnMid>
						<ColumnMid>
						<NavLink to='/loodgieter'>Loodgieterij</NavLink>
						<NavLink to='/tuinwerken'>Tuinwerken</NavLink>
						<NavLink to='/computer'>Computer problemen</NavLink>
						<NavLink to='/vervoer'>Vervoer</NavLink>
						<NavLink to='/kleinevaria'>Andere projectjes</NavLink>
						</ColumnMid>
						</GridMid>
					</Column>
					<Column>
						<HeaderBig>Grote werken</HeaderBig>
						<GridMid>
						<ColumnMid>
						<p><NavLink to='/afbraakwerken'>Afbraakwerken</NavLink></p>
						<p><NavLink to='/opruimingswerken'>Opruimen</NavLink></p>
						<p><NavLink to='/electriteit'>Electriciteit</NavLink></p>
						<p><NavLink to='/loodgieter'>Loodgieterij</NavLink></p>
						</ColumnMid>
						<ColumnMid>
						<p><NavLink to='/stellingswerken'>Stellingswerken</NavLink></p>
						<p><NavLink to='/dakwerken'>Dakwerken</NavLink></p>
						<p><NavLink to='/bedrijven'>Voor Ondernemingen en Syndici</NavLink></p>
						<p><NavLink to='/grotevaria'>Varia</NavLink></p>
						</ColumnMid>
						</GridMid>
					</Column>
				</DetailGrid>
			</ProjectContainer>
		</SubContainer>
	</MainContainer>
);
};

export default Home;
