import React from 'react';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn } from '../components/detailcomponents';

import meneer from '../images/StandaardMeneer.png'

const vervoer = () => {
return (
	<MainContainer>
	<head><meta name="keywords" content="Kempen, vervoer, containerpark, opruimen, lier, antwerpen, zoersel"/></head>
	<Title>Vervoer</Title>
	<SubContainer>
		<ProjectContainer>
			<DetailGrid>
				<DetailColumn>
					<Image src={meneer} height="250rem" />
				</DetailColumn>
				<DetailColumn>
					<Text>
					Heb je thuis nog veel spullen staan die naar het containerpark moeten? Of staat er al een tijdje een paar dozen in de weg, die eigenlijk wel weg mogen? <br />
					Bel de fixbus!
					</Text>
					<ul>
						<li>Groenafval naar het containerpark vervoeren</li>
						<li>Opruimingen</li>
						<li>En nog meer!</li>
					</ul>
				</DetailColumn>
			</DetailGrid>
		</ProjectContainer>
	</SubContainer>
	</MainContainer>
);
};

export default vervoer;
