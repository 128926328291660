import React from 'react';
import { NavLink } from 'react-router-dom';
import { MainContainer, SubContainer, ProjectContainer, Image, Title, Grid, Column, Text } from '../components/detailcomponents';

import dakwerken from '../images/Dakwerken.png'
import electriciteit from '../images/Elektriciteit.png'
import computer from '../images/IT.png'
import loodgieterij from '../images/Loodgieterij.png'
import schilderwerken from '../images/Schilderwerken.png'
import vervoer from '../images/Vervoer.png'
import afwerking from '../images/Afwerkingen.png'
import afbraakwerken from '../images/Afbraakwerken.png'
import kleinigheid from '../images/KleineProjectjes.png'
import opruimen from '../images/Opruimen.png'
import stellingswerken from '../images/Stellingswerk.png'
import tuinwerken from '../images/Tuinwerken.png'
import varia from '../images/Varia.png'

const diensten = () => {
return (
	<MainContainer>
    <head><meta name="keywords" content="Kempen, klusjesdienst, werken, taak, lier, antwerpen, zoersel"/></head>
	<Title>Onze diensten</Title>

	<SubContainer>
	<ProjectContainer>
		<Text>
			<p>Heb je een klusje te doen in huis en je besteedt dit liever uit aan echte vakmensen? <br/>
			Twijfel niet en contacteer ons snel! <br/><br/>
			Wij komen heel graag vrijblijvend naar je toe om uw werkjes samen te bespreken om aansluitend een correcte offerte aan te bieden!
			</p>
		</Text>
	</ProjectContainer>
	</SubContainer>

	<SubContainer>
		<ProjectContainer>
		<Grid>
			<Column>
				<NavLink to='/afbraakwerken'>
				<p>Afbraakwerken</p>
				<Image src={afbraakwerken} style={{width: '6rem'}} alt="IconAfbraakwerken"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/kleinevaria'>
				<p>Kleine Projectjes</p>
				<Image src={kleinigheid} style={{width: '6rem'}} alt="IconKleineWerken"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/opruimingen'>
				<p>Opruimen</p>
				<Image src={opruimen} style={{width: '6rem'}} alt="IconOpruimingen"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/afwerking'>
				<p>Afwerkingen</p>
				<Image src={afwerking} style={{width: '6rem'}} alt="IconAfwerking"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/schilderwerken'>
				<p>Schilderwerken</p>
				<Image src={schilderwerken} style={{width: '6rem'}} alt="IconSchilderwerken"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/electriteit'>
				<p>Electriciteit</p>
				<Image src={electriciteit} style={{width: '6rem'}} alt="IconElectriciteit"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/loodgieter'>
				<p>Loodgieterij</p>
				<Image src={loodgieterij} style={{width: '6rem'}} alt="IconLoodgieterij"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/stellingswerken'>
				<p>Stellingswerken</p>
				<Image src={stellingswerken} style={{width: '6rem'}} alt="IconStellingswerken"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/dakwerken'>
				<p>Dakwerken</p>
				<Image src={dakwerken} style={{width: '6rem'}} alt="IconDakwerken"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/tuinwerken'>
				<p>Tuinwerken</p>
				<Image src={tuinwerken  } style={{width: '6rem'}} alt="IconTuinwerken"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/computer'>
				<p>Computer problemen</p>
				<Image src={computer} style={{width: '6rem'}} alt="IconComputerProblemen"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/vervoer'>
				<p>Vervoer</p>
				<Image src={vervoer} style={{width: '6rem'}} alt="IconVervoer"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/ondernemingen'>
				<p>Voor bedrijven en Syndici</p>
				<Image src={varia} style={{width: '6rem'}} alt="IconGroteWerken"/>
				</NavLink>
			</Column>
			<Column>
				<NavLink to='/grotevaria'>
				<p>Varia</p>
				<Image src={varia} style={{width: '6rem'}} alt="IconGroteWerken"/>
				</NavLink>
			</Column>
		</Grid>
		</ProjectContainer>
	</SubContainer>

	</MainContainer>
);	
};

export default diensten;
