import styled from 'styled-components';

export const Title = styled.h1`
    font-family: Tahoma;
`;

export const SubTitle = styled.h3`
    font-family: Tahoma;
`;

export const Text = styled.p`
    font-family: Tahoma;
    margin: .2rem;
    padding: 0rem;
    color: #000000;
`;

export const PrimaryButton = styled.button`
    color: #000000;
    background: #2a4d82;
    padding: .6rem 1rem;
    margin: 0 1rem;
    font-size: 12pt;
    border: 1px;
    border-radius: .2rem;
`;

export const SecondaryButton = styled.button`
    color: #000000;
    background: #87FF56;
    padding: .5rem 1rem;
    margin: 0 1rem;
    font-size: 12pt;
    border: 1px;
    border-radius: .2rem;
`;

export const Href = styled.a`
    text-decoration: none;
    color: #000000;
`;

export const MainContainer = styled.div`
    padding: 2% 10%;
`;

export const SubContainer = styled.div`
    background: #f2f2f2;
    padding: .5rem 2rem;
    border: 1px #f2f2f2;
    border-radius: .5rem;
    margin-bottom: 2rem;
`;

export const ProjectContainer = styled.div`
    display: grid;
    border: 1px #f2f2f2;
    border-radius: .5rem;
    width: 100%;

    form{
        box-sizing: border-box;
    }

    label{
        width: 8rem;
    }

    input[type=text], input[type=tel], input[type=email] {
        width: 80%;
        padding: .8rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: vertical;
      }

      select{
        width: 83%;
        padding: .8rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: vertical;
        margin-bottom: 0.2rem;
      }

      textarea{
        width: 80%;
        height: 5rem;
        padding: .8rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: none;
      }
      
      label {
        padding: 12px 12px 12px 0;
        display: inline-block;
      }
      
      input[type=submit] {
        background-color: #1459CF;
        color: white;
        padding: .8rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        width: 40%;
      }
      
      input[type=submit]:hover {
        background-color: #87FF56;
        color: #000000;
      }
`;

export const PrivacyContainer = styled.div`
    display: grid;
    border: 1px #ededed;
    border-radius: .5rem;
    width: 100%;
    text-align: left;
`;

export const Image = styled.img`
    padding: 0rem 1.5rem;
    vertical-align: middle;
`;
export const ImageGrid = styled.img`
    vertical-align: middle;
`;

export const Grid = styled.div`
    font-family: Tahoma;
    display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto; 
    grid-gap: 10px;  

    @media(max-width:700px) {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto; 
    }
`;

export const Column = styled.div`
    float: left;
    padding: .5rem;

    a {
        color: #000000;
        text-decoration: none;
    }
`;  


export const DetailGrid = styled.div`
    font-family: Tahoma;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;  
    grid-gap: 10px;  

    @media(max-width:700px) {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto; 
    }
`;

export const DetailColumn = styled.div`
    float: left;
    padding: .5rem;
    text-align: left;
    align-self: center;
`; 

export const List = styled.ul`
    list-style-type: circle;
    text-align: left;
`; 

export const ListItem = styled.li`
    list-style-type: circle;
    float: left;
    text-align: left;
    padding-left: 0;
    padding-bottom: .5rem;
`; 