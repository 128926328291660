import React from 'react';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn } from '../components/detailcomponents';

import meneer from '../images/StandaardMeneer.png'

const bedrijven = () => {
return (
	<MainContainer>
    <head><meta name="keywords" content="Kempen, bedrijven, syndici, gyproc, plamuren, muur, lier, antwerpen, zoersel"/></head>
	<Title>Bedrijven en Syndici</Title>

<SubContainer>
	<ProjectContainer>
		<DetailGrid>
			<DetailColumn>
				<Image src={meneer} height="250rem" />
			</DetailColumn>
			<DetailColumn>
				<Text>
				Onderhoud nodig voor gemeenschappelijke ruimtes of onderhoud van een bedrijfspand, bel ons op!
				</Text>
				<ul>
					<li>Onderhoudswerken</li>
					<li>Renovaties</li>
					<li>En nog veel meer!</li>
				</ul>
			</DetailColumn>
		</DetailGrid>
	</ProjectContainer>
</SubContainer>
	</MainContainer>
);	
};

export default bedrijven;
