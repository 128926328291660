import React from 'react';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn } from '../components/detailcomponents';

import meneer from '../images/StandaardMeneer.png'

const schilderwerken = () => {
return (
	<MainContainer>
    	<head><meta name="keywords" content="Kempen, schilder, schilderwerken, behangen, behang, lier, antwerpen, zoersel"/></head>
		<Title>Schilderwerken</Title>

		<SubContainer>
			<ProjectContainer>
				<DetailGrid>
					<DetailColumn>
						<Image src={meneer} height="250rem" />
					</DetailColumn>
					<DetailColumn>
						<Text>
						Ons team biedt je een totaalpakket aan. Met de beste materialen toveren we je woning om tot je droomhuis. <br />
						Samen bespreken we de mogelijkheden, kiezen we de kleuren en plannen we samen de werken.
						</Text>
						<ul>
							<li>Woning verkoopsklaar maken (alles wit)</li>
							<li>Eén kamer schilderen</li>
							<li>Meerdere kamers schilderen</li>
							<li>Eén kamer behangen</li>
							<li>Meerdere kamers behangen</li>
						</ul>
					</DetailColumn>
				</DetailGrid>
			</ProjectContainer>
		</SubContainer>
	</MainContainer>
);
};

export default schilderwerken;
