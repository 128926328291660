import React from 'react';
import { NavLink } from 'react-router-dom';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn } from '../components/detailcomponents';

import meneer from '../images/StandaardMeneer.png'

const grotevaria = () => {
return (
	<MainContainer>
	<Title>Varia</Title>

<SubContainer>
	<ProjectContainer>
		<DetailGrid>
			<DetailColumn>
				<Image src={meneer} height="250rem" />
			</DetailColumn>
			<DetailColumn>
				<Text>
				Niet gevonden wat je graag zou willen laten doen? Stuur ons een berichtje! Dan kunnen we samen kijken naar de mogelijkheden. <br />
				<NavLink to='/contact'>Vul hier een contact formulier in</NavLink>
				</Text>
			</DetailColumn>
		</DetailGrid>
	</ProjectContainer>
</SubContainer>
		
	</MainContainer>
);
};

export default grotevaria;
