import React from 'react';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn } from '../components/detailcomponents';

import meneer from '../images/StandaardMeneer.png'

const afwerking = () => {
return (
	<MainContainer>
    <head><meta name="keywords" content="Kempen, afwerking, gyproc, plamuren, muur, lier, antwerpen, zoersel"/></head>
	<Title>Afwerkingen</Title>

<SubContainer>
	<ProjectContainer>
		<DetailGrid>
			<DetailColumn>
				<Image src={meneer} height="250rem" />
			</DetailColumn>
			<DetailColumn>
				<Text>
				Je hebt bepaalde werken laten uitvoeren en nu blijkt dat het nog deftig dient afgewerkt te worden. <br />
				Zoals plaatsen van een gyproc-muurtje, voegen van spleten in de muur of afdichtingen. Contacteer ons!
				</Text>
				<ul>
					<li>Plamuurwerken</li>
					<li>Plinten plaatsen</li>
					<li>Deuromlijstingen plaatsen</li>
					<li>Plafondlijst plaatsen</li>
					<li>En nog veel meer!</li>
				</ul>
			</DetailColumn>
		</DetailGrid>
	</ProjectContainer>
</SubContainer>
	</MainContainer>
);	
};

export default afwerking;
