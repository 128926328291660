import React from 'react';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn } from '../components/detailcomponents';

import meneer from '../images/StandaardMeneer.png'

const stellingswerken = () => {
return (
	<MainContainer>
	<head><meta name="keywords" content="Kempen, stelling, stellingswerken, lier, antwerpen, zoersel"/></head>
	<Title>Stellingswerken</Title>
	
	<SubContainer>
			<ProjectContainer>
				<DetailGrid>
					<DetailColumn>
						<Image src={meneer} height="250rem" />
					</DetailColumn>
					<DetailColumn>
						<Text>
						Een stelling plaatsen? Een trappentoren plaatsen? <br />
						Bel ons  onze vakcollega’s komen direct bij u ter plaatsen!
						</Text>
						<ul>
							<li>Stellingswerken</li>
							<li>Trappentoren plaatsen</li>
							<li>Meerdere kamers schilderen</li>
							<li>Eén kamer behangen</li>
							<li>Meerdere kamers behangen</li>
						</ul>
					</DetailColumn>
				</DetailGrid>
			</ProjectContainer>
		</SubContainer>
		
	</MainContainer>
);
};

export default stellingswerken;
