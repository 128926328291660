import React from 'react';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn } from '../components/detailcomponents';

import meneer from '../images/StandaardMeneer.png'

const dakwerken = () => {
return (
	<MainContainer>
    <head><meta name="keywords" content="Kempen, dakwerken, schouw, dakpannen, lier, antwerpen, zoersel"/></head>
	<Title>Dakwerken</Title>

	<SubContainer>
		<ProjectContainer>
			<DetailGrid>
				<DetailColumn>
					<Image src={meneer} height="250rem" />
				</DetailColumn>
				<DetailColumn>
					<Text>
					Voor alle dakwerken groot en klein moet je echt wel bij  de Fixbus zijn. <be />
					Onze collega’s maken je de beste offerte en komen vrijblijvend langs!
					</Text>
					<ul>
						<li>Dak leggen</li>
						<li>Dak vervangen</li>
						<li>Dakpannen vervangen</li>
						<li>Schoorteen kuisten</li>
						<li>Nog veel meer!</li>
					</ul>
				</DetailColumn>
			</DetailGrid>
		</ProjectContainer>
	</SubContainer>
	</MainContainer>
);
};

export default dakwerken;
