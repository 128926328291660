import styled from 'styled-components';

export const AppHeader = styled.header`
    font-family: Tahoma;
    display: flex;
    flex-direction: column;
    background: #2e73ea;
    font-size: calc(10px + 2vmin);
`;

export const HeaderTitle = styled.h3`
    font-family: Tahoma;
    margin: 0rem;
    color: white;
`;

export const Title = styled.h1`
    font-family: Tahoma;
`;

export const SubTitle = styled.h4`
    font-family: Tahoma;
    margin: 0rem;
`;

export const Text = styled.p`
    margin: 0rem;
    text-align: center;
    font-family: Tahoma;
    font-size: 12pt;
`;

export const PrimaryButton = styled.button`
    color: #ffffff;
    background: #1459CF;
    padding: .6rem 1rem;
    margin: 0 1rem;
    font-size: 12pt;
    border: 1px;
    border-radius: .2rem;
    font-family: Tahoma;
`;

export const SecondaryButton = styled.button`
    color: #000000;
    background: #5bff1a;
    padding: .5rem 1rem;
    margin: 0 1rem;
    font-size: 12pt;
    border: 1px;
    border-radius: .2rem;
    font-family: Tahoma;
`;

export const Href = styled.a`
    text-decoration: none;
    color: #000000;
`;

export const List = styled.ul`
    text-align: center;
    list-style: none;
`;

export const Grid = styled.div`
    font-family: Tahoma;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;  
    grid-gap: 10px;  

    @media(max-width:700px) {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto; 
    }
`;

export const Column = styled.div`
    float: left;
    padding: .5rem;
    text-align: center;
`;
export const ColumnRight = styled.div`
    float: right;
    padding: .5rem;
    text-align: right;
    padding: 0rem 3rem;
`;  
export const ColumnLeft = styled.div`
    float: left;
    padding: .5rem;
    text-align: left;
    padding: 1.5rem 3rem;
`;  

export const GridMid = styled.div`
    font-family: Tahoma;
    display: grid;
    Padding: 0rem 1.5rem;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;  
    grid-gap: 10px;  

    @media(max-width:700px) {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto; 
    }
`;

export const ColumnMid = styled.div`
    float: left;
    padding: .5rem;
    text-align: left;
`; 

export const HeaderSmall = styled.h3`
    font-family: Tahoma;
    color: #000000;
    background: #5bff1a;
    padding: 1rem 0.5rem;
    border-radius: .5rem;
`;  

export const HeaderBig = styled.h3`
    font-family: Tahoma;
    color: #000000;
    background: #2e73ea;
    padding: 1rem 0.5rem;
    border-radius: .5rem;
`;  