import React from 'react';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn } from '../components/detailcomponents';

import meneer from '../images/StandaardMeneer.png'

const tuinwerken = () => {
return (
	<MainContainer>
	<head><meta name="keywords" content="Kempen, tuinwerken, tuinonderhoud, snoeiwerken, snoeien, lier, antwerpen, zoersel"/></head>
	<Title>Tuinwerken</Title>

	<SubContainer>
		<ProjectContainer>
			<DetailGrid>
				<DetailColumn>
					<Image src={meneer} height="250rem" />
				</DetailColumn>
				<DetailColumn>
					<Text>
					U heeft niet echt groene vingers of gewoonweg geen tijd? <br />
					Graag, met liefde en plezier, komen wij u graag helpen om uw tuin mooi en groen te houden!
					</Text>
					<ul>
						<li>Snoeiwerken</li>
						<li>Onderhoudswerken</li>
						<li>Tuin aanleggen</li>
						<li>Zwembad aanleggen</li>
						<li>Tuinhuis plaatsen</li>
						<li>En nog veel meer...</li>
					</ul>
				</DetailColumn>
			</DetailGrid>
		</ProjectContainer>
	</SubContainer>
		
	</MainContainer>
);
};

export default tuinwerken;
