import React from 'react';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn } from '../components/detailcomponents';

import meneer from '../images/StandaardMeneer.png'

const electriciteit = () => {
return (
	<MainContainer>
    <head><meta name="keywords" content="Kempen, electricteit, leiding, lampen, stopcontact, lichtpunt, lier, antwerpen, zoersel"/></head>
	<Title>Elektriciteit</Title>

<SubContainer>
	<ProjectContainer>
		<DetailGrid>
			<DetailColumn>
				<Image src={meneer} height="250rem" />
			</DetailColumn>
			<DetailColumn>
				<Text>
				Een kapotte lamp? Zit niet langer in de duisternis, wij helpen je uit de nood. <br />
				Stopcontact verleggen? Extra kabel trekken? Onze vakmensen komen zo!
				</Text>
				<ul>
					<li>Nieuwe leidingen leggen</li>
					<li>Lampen vervangen</li>
					<li>Nieuw stopcontact toevoegen</li>
					<li>Nieuw lichtpunt toevoegen</li>
					<li>En nog veel meer!</li>
				</ul>
			</DetailColumn>
		</DetailGrid>
	</ProjectContainer>
</SubContainer>
	</MainContainer>
);
};

export default electriciteit;
