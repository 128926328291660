import React from 'react';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn } from '../components/detailcomponents';

import meneer from '../images/StandaardMeneer.png'

const opruimingen = () => {
return (
	<MainContainer>
    <head><meta name="keywords" content="Kempen, opruimingen, opruimen, lier, antwerpen, zoersel"/></head>
	<Title>Opruimingen</Title>

<SubContainer>
	<ProjectContainer>
		<DetailGrid>
			<DetailColumn>
				<Image src={meneer} height="250rem" />
			</DetailColumn>
			<DetailColumn>
				<Text>
				Een vervelend en gevoelig gebeuren, voor een persoonlijke reden dient de volledige inboedel van een woning leeg gehaald worden.<br />
				Wij begeleiden je door dit hele proces en laten de woning in spik en span staat netjes achter.
				</Text>
				<ul>
					<li>Kamer leeghalen (kelder, zolder, ...)</li>
					<li>Woning leeghalen</li>
				</ul>
			</DetailColumn>
		</DetailGrid>
	</ProjectContainer>
</SubContainer>
		
	</MainContainer>
);
};

export default opruimingen;
