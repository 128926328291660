import React from 'react';
import { MainContainer, SubContainer, ProjectContainer, Text, Image, Title, DetailGrid, DetailColumn } from '../components/detailcomponents';

import meneer from '../images/StandaardMeneer.png'

const opruimingswerken = () => {
return (
	<MainContainer>
    <head><meta name="keywords" content="Kempen, opruimingen, opruimen, lier, antwerpen, zoersel"/></head>
	<Title>Opruimingswerken</Title>

	<SubContainer>
		<ProjectContainer>
			<DetailGrid>
				<DetailColumn>
					<Image src={meneer} height="250rem" />
				</DetailColumn>
				<DetailColumn>
					<Text>
					Speciaal voor groten aannemers reinigen wij de werven na de gedane werken.
					</Text>
				</DetailColumn>
			</DetailGrid>
		</ProjectContainer>
	</SubContainer>
		
	</MainContainer>
);
};

export default opruimingswerken;
