import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
background: #2e73ea;
display: flex;
justify-content: center;
padding: 1rem;
`;

export const NavLink = styled(Link)`
color: #FFFFFF;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 2rem;
height: 100%;
cursor: pointer;
font-size: 13pt;
&.active {
	color: #FFFFFF;
}
`;

export const NavLinkHide1 = styled(Link)`
color: #FFFFFF;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 2rem;
height: 100%;
cursor: pointer;
font-size: 13pt;
&.active {
	color: #FFFFFF;
}

@media screen and (max-width: 768px) {
	display: none;
}
`;

export const NavLinkHide2 = styled(Link)`
color: #FFFFFF;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 2rem;
height: 100%;
cursor: pointer;
font-size: 13pt;
&.active {
	color: #FFFFFF;
}

@media screen and (max-width: 1180px) {
	display: none;
}
`;

export const Bars = styled(FaBars)`
display: none;
color: #808080;
@media screen and (max-width: 768px) {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-100%, 75%);
	font-size: 1.8rem;
	cursor: pointer;
	color:#51FA0B;
}
`;

export const NavMenu = styled.div`
display: flex;
align-items: center;
margin-right: -24px;

/* @media screen and (max-width: 768px) {
	display: none;
}*/
`;

export const NavBtn = styled.nav`
display: flex;
align-items: center;
margin-right: 24px;

/* @media screen and (max-width: 768px) {
	display: none;
}*/
`;

export const NavBtnLink = styled(Link)`
border-radius: 4px;
background: #808080;
padding: 10px 22px;
color: #000000;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;
/* Second Nav */
margin-left: 24px;
&:hover {
	transition: all 0.2s ease-in-out;
	background: #fff;
	color: #808080;
}
`;

export const Text = styled.p`
    margin: 0rem;
    text-align: center;
    font-family: Tahoma;
    font-size: 12pt;
	Color: white;
`;
