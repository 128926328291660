import './App.css';
import Navbar from './components/navbar';
import Header from './components/header';
import Footer from './components/footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages';
import Afwerking from './pages/afwerking';
import Computer from './pages/computer';
import Dakwerken from './pages/dakwerken';
import Elektriciteit from './pages/elektriciteit';
import Loodgieterij from './pages/loodgieter';
import Schilderwerken from './pages/schilderwerken';
import Vervoer from './pages/vervoer';
import Diensten from './pages/diensten';
import Opruimingen from './pages/opruimingen';
import Tuinwerken from './pages/tuinwerken';
import KleineVaria from './pages/kleinevaria';
import GroteVaria from './pages/grotevaria';
import Ondernemingen from './pages/bedrijven';
import Stellingswerken from './pages/stellingswerken';
import Opruimingswerken from './pages/opruimingswerken';
import Afbraakwerken from './pages/afbraakwerken';
import Privacy from './pages/privacy';
import Contact from './pages/contact';

function App() {
  return (
    <>
    <Header />
    <Router>
    <Navbar />
    <Routes>
      <Route path='/' exact component={Home} element={<Home />} />
      <Route path='/diensten' component={Diensten} element={<Diensten />} />
      <Route path='/afwerking' component={Afwerking} element={<Afwerking />} />
      <Route path='/schilderwerken' component={Schilderwerken} element={<Schilderwerken />} />
      <Route path='/elektriciteit' component={Elektriciteit} element={<Elektriciteit />} />
      <Route path='/loodgieter' component={Loodgieterij} element={<Loodgieterij />} />
      <Route path='/dakwerken' component={Dakwerken} element={<Dakwerken />} />
      <Route path='/computer' component={Computer} element={<Computer />} />
      <Route path='/vervoer' component={Vervoer} element={<Vervoer />} />
      <Route path='/opruimingen' component={Opruimingen} element={<Opruimingen />} />
      <Route path='/tuinwerken' component={Tuinwerken} element={<Tuinwerken />} />
      <Route path='/stellingswerken' component={Stellingswerken} element={<Stellingswerken />} />
      <Route path='/opruimingswerken' component={Opruimingswerken} element={<Opruimingswerken />} />
      <Route path='/afbraakwerken' component={Afbraakwerken} element={<Afbraakwerken />} />
      <Route path='/kleinevaria' component={KleineVaria} element={<KleineVaria />} />
      <Route path='/grotevaria' component={GroteVaria} element={<GroteVaria />} />
      <Route path='/bedrijven' component={Ondernemingen} element={<Ondernemingen />} />
      <Route path='/contact' component={Contact} element={<Contact />} />
      <Route path='/privacy' component={Privacy} element={<Privacy />} />
    </Routes>
  </Router>
  <Footer />
  </>
  );
}

export default App;
